import React, { useContext, useRef, useMemo } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import ReactToPrint from "react-to-print";
import { Logo } from "components";
import { MdClose, MdPrint } from "react-icons/md";
import CustomersDetailsContext from "../context";
import { MeetingsNotesContext } from "./index";

const PrintDetailing = ({ isOpen, onClose }) => {
  const { formData } = useContext(CustomersDetailsContext);
  const { meetingsNotes } = useContext(MeetingsNotesContext);
  const { colorMode } = useColorMode();
  const colorScheme = useMemo(() => (colorMode === "light" ? "blackAlpha" : "whiteAlpha"[colorMode]));
  const bodyRef = useRef();

  return (
    <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={HStack} justifyContent="flex-end" position="fixed" top="0" left="0" w="100%" py="10px" px="20px">
          <ReactToPrint
            content={() => bodyRef.current}
            trigger={() => <IconButton colorScheme={colorScheme} icon={<Icon as={MdPrint} />} />}
          />
          <IconButton colorScheme={colorScheme} icon={<Icon as={MdClose} />} onClick={onClose} />
        </ModalHeader>
        <ModalBody p="10px" ref={bodyRef}>
          <HStack borderWidth="1px" p="10px">
            <Logo width={140} />
            <Heading flex="1" textAlign="center" size="sm">
              Notas de reunião
            </Heading>
            <Box width="140px" textAlign="right">
              <Text fontSize="xs">Data de impressão</Text>
              <Text fontSize="xs" fontWeight="bold">
                {moment().format("DD/MM/YYYY [às] HH:mm")}
              </Text>
            </Box>
          </HStack>

          <Grid templateColumns="repeat(12, 1fr)" borderWidth="1px" p="10px" my="10px">
            <GridItem colSpan={4}>
              <Text fontSize="xs">Cliente</Text>
              <Text fontSize="xs" fontWeight="bold">
                {formData.nid?.toLocaleString()} - {formData.tradingName}
              </Text>
            </GridItem>
            <GridItem colSpan={4}></GridItem>
            <GridItem colSpan={4}></GridItem>
          </Grid>

          <Box borderWidth="1px">
            {_.map(meetingsNotes?.data, (item) => (
              <Grid key={item._id} templateColumns="repeat(12, 1fr)" fontSize="sm" className="no-break">
                <GridItem colSpan={6} p="10px">
                  <Text fontWeight="semibold">{item.finishedBy?.name || "-"}</Text>
                  <Text fontSize="xs">Reunião finalizada em {moment(item.finishedAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
                </GridItem>
                <GridItem colSpan={6} p="10px" textAlign="right">
                  <Text fontSize="xs">Tipo de reunião</Text>
                  <Text fontSize="sm">{item.meetingType?.title || "-"}</Text>
                </GridItem>
                <GridItem colSpan={12} px="10px" pt="0" pb="5px" fontSize="xs" textAlign="left" borderBottomWidth="1px">
                  <strong>Descrição: </strong> {item.notes}
                </GridItem>
              </Grid>
            ))}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PrintDetailing;
