import moment from "moment";
import { Link as Routerlink } from "react-router-dom";
import { Link } from "@chakra-ui/react";

const defaultColumns = [
  {
    accessor: "email",
    title: "E-mail",
    isVisible: true,
    render: (item, { setSelected }) => (item.meeting ? item.email : <Link onClick={() => setSelected(item)}>{item.email}</Link>),
    exporter: ({ email }) => email,
  },
  {
    accessor: "startDate",
    title: "Início",
    isVisible: true,
    formatter: ({ startDate }) => moment(startDate).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "endDate",
    title: "Fim",
    isVisible: true,
    formatter: ({ endDate }) => moment(endDate).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "meeting",
    title: "Reunião",
    isVisible: true,
    isSortable: false,
    render: ({ meeting }) =>
      meeting?.nid ? (
        <Link as={Routerlink} to={`/meetings/edit/${meeting._id}`} target="_blank">
          #{meeting.nid}
        </Link>
      ) : (
        "-"
      ),
    exporter: ({ meeting }) => meeting?.nid || "-",
  },
  {
    accessor: "meetingRoom",
    title: "Sala de reunião",
    isVisible: true,
    isSortable: false,
    formatter: ({ meetingRoom }) => meetingRoom?.title ?? "-",
  },
  {
    accessor: "description",
    title: "Descrição",
    isVisible: false,
    formatter: ({ description }) => description ?? "-",
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    isSortable: false,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
