import React, { useContext, useState, useCallback, useMemo, Fragment, forwardRef, useImperativeHandle, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Switch,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useCustomToast } from "hooks";
import { messages } from "consts";
import { EventEmitter, api, currency, translator, yup } from "lib";
import MeetingsDetailsContext from "./context";
import { SyncSelect } from "components";

const StatusChange = forwardRef((props, ref) => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const { formData: parentFormData, refreshData, counters, setTabIndex } = useContext(MeetingsDetailsContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const isClosing = useMemo(
    () => _.includes(["finished", "canceled_by_customer", "canceled_by_consultant", "canceled_by_scheduling"], formData.status),
    [formData.status]
  );
  const isFinishing = useMemo(() => formData.status === "finished", [formData.status]);
  const isAllowedTravelCost = useMemo(
    () => formData.status === "finished" && parentFormData.placement === "external" && parentFormData.address?.travelCostAmount > 0,
    [formData.status, parentFormData.placement, parentFormData.address?.travelCostAmount]
  );
  const users = useMemo(() => _.map(parentFormData.participants, (o) => o.user), [parentFormData.participants]);
  const { isOpen: isOpenTasksDialog, onOpen: onOpenTasksDialog, onClose: onCloseTasksDialog } = useDisclosure();
  const { isOpen: isOpenFilesDialog, onOpen: onOpenFilesDialog, onClose: onCloseFilesDialog } = useDisclosure();
  const { isOpen: isOpenUpdateFilesDialog, onOpen: onOpenUpdateFilesDialog, onClose: onCloseUpdateFilesDialog } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    onCloseUpdateFilesDialog();
  }, [formData.status]);

  const open = useCallback(
    (status) => {
      if (status === "finished") {
        if (counters.files === 0) onOpenFilesDialog();
        else onOpenUpdateFilesDialog();
      } else setFormData({ status, travelCostAmount: 0 });
    },
    [counters.files]
  );

  const close = useCallback(() => {
    setFormData({});
    setFormErrors({});
  }, []);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = await api.patch(`/meetings/${_id}/status`, data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
        if (saved.finishedAt) onOpenTasksDialog();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
        close();
      }
    },
    [_id, refreshData, close, toast, onOpenTasksDialog]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const data = { ...formData, travelCostPaidBy: formData.travelCostPaidBy?._id };
      if (isClosing) {
        const shape = { notes: yup.string().required(messages.error.required).isValidNotes() };
        if (data.travelCostAmount > 0) shape.travelCostPaidBy = yup.string().required(messages.error.required);
        const schema = yup.object().shape(shape);
        await schema.validate(data, { abortEarly: false });
      }
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [isClosing, formData, handleSaveData]);

  const handleFinishFilesConfirm = useCallback(
    (action) => {
      switch (action) {
        case "skip":
          // setFormData({ status: "finished", travelCostAmount: 0 });
          onOpenUpdateFilesDialog();
          break;
        case "add":
          EventEmitter.emit("files.create.".concat(_id));
          break;
      }
      onCloseFilesDialog();
    },
    [_id]
  );

  const handleNavigateDerivedTasks = useCallback(() => {
    const date = moment(parentFormData.startDate).add(1, "month");
    navigate("/tasks/new", {
      state: {
        customer: parentFormData.customer,
        referenceDate: date.startOf("month").toDate(),
        dueDate: date.endOf("month").toDate(),
        meeting: parentFormData._id,
      },
    });
  }, [parentFormData._id, parentFormData.customer, parentFormData.startDate]);

  const handleUpdateFiles = useCallback(() => {
    onCloseUpdateFilesDialog();
    setTabIndex(1);
  }, []);

  useImperativeHandle(ref, () => ({ open, close }), [open, close]);

  return (
    <Fragment>
      <AlertDialog isOpen={isOpenUpdateFilesDialog} onClose={onCloseUpdateFilesDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atualizar arquivos</AlertDialogHeader>
          <AlertDialogBody>
            <Text>Deseja atualizar algum dos arquivos das tarefas desta reunião?</Text>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" variant="outline" onClick={() => setFormData({ status: "finished", travelCostAmount: 0 })}>
              não
            </Button>
            <Button size="sm" colorScheme="main" onClick={handleUpdateFiles}>
              sim
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog size="xl" isOpen={_.isString(formData.status)} onClose={close} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>
            <Text>
              Deseja alterar a reunião para o status "<strong>{translator(formData.status)}</strong>"? Este é um procedimento irreversível.
            </Text>
            {isClosing && <Divider my={4} />}
            <VStack alignItems="stretch" spacing={4}>
              {isAllowedTravelCost && (
                <FormControl
                  as={HStack}
                  p="15px"
                  borderWidth="1px"
                  borderRadius="lg"
                  isRequired={true}
                  isInvalid={formErrors.travelCostAmount}
                >
                  <Box flex="1">
                    <FormLabel fontSize="sm" m="0">
                      Gerar custo de viagem?
                    </FormLabel>
                    <FormHelperText m="0">
                      O custo de viagem cadastrado para este cliente é de {currency.format(parentFormData.address.travelCostAmount)}.
                    </FormHelperText>
                    <FormErrorMessage>{formErrors.travelCostAmount}</FormErrorMessage>
                  </Box>
                  <Switch
                    colorScheme="green"
                    isChecked={formData.travelCostAmount > 0}
                    onChange={() =>
                      setFormData((state) => ({
                        ...state,
                        travelCostAmount: state.travelCostAmount > 0 ? 0 : parentFormData.address.travelCostAmount,
                      }))
                    }
                  />
                </FormControl>
              )}
              {formData.travelCostAmount > 0 && (
                <FormControl
                  as={HStack}
                  p="15px"
                  borderWidth="1px"
                  borderRadius="lg"
                  isRequired={true}
                  isInvalid={formErrors.travelCostPaidBy}
                >
                  <Box flex="1">
                    <FormLabel fontSize="sm" m="0">
                      Custo de viagem pago por
                    </FormLabel>
                    <FormHelperText m="0">Informe o consultor que deverá receber o reembolso do custo de viagem.</FormHelperText>
                    <FormErrorMessage>{formErrors.travelCostPaidBy}</FormErrorMessage>
                  </Box>
                  <SyncSelect
                    value={formData.travelCostPaidBy}
                    options={users}
                    placeholder="Selecione"
                    onChange={(travelCostPaidBy) => setFormData((state) => ({ ...state, travelCostPaidBy }))}
                    getOptionValue={({ _id }) => _id}
                    formatOptionLabel={({ name }) => name}
                    isClearable={true}
                  />
                </FormControl>
              )}
              {isClosing && (
                <FormControl isRequired={true} isInvalid={formErrors.notes}>
                  <FormLabel fontSize="sm">{formData.status === "finished" ? "Ata da reunião" : "Notas"}</FormLabel>
                  <Textarea
                    value={formData.notes ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, notes: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.notes}</FormErrorMessage>
                </FormControl>
              )}
              {isFinishing && (
                <FormControl isInvalid={formErrors.technicalNotes}>
                  <FormLabel fontSize="sm">Observações técnicas</FormLabel>
                  <Textarea
                    value={formData.technicalNotes ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, technicalNotes: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.technicalNotes}</FormErrorMessage>
                </FormControl>
              )}
            </VStack>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" variant="outline" onClick={close}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="main" isLoading={isLoadingSaveData} onClick={handleSubmit}>
              confirmar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog isOpen={isOpenTasksDialog} closeOnEsc={false} closeOnOverlayClick={false} isCentered scrollBehavior="inside">
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>
            <Text>Deseja criar uma Tarefa derivada desta reunião?</Text>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" variant="outline" onClick={onCloseTasksDialog}>
              não
            </Button>
            <Button size="sm" colorScheme="main" onClick={handleNavigateDerivedTasks}>
              sim
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog isOpen={isOpenFilesDialog} onClose={onCloseFilesDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Ata da reunião</AlertDialogHeader>
          <AlertDialogBody fontSize="sm">
            Antes de finalizar a reunião, você gostaria de adicionar os arquivos de ata da reunião?
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justifyContent="flex-end">
            <Button size="sm" variant="outline" onClick={handleFinishFilesConfirm.bind(this, "skip")}>
              pular
            </Button>
            <Button size="sm" colorScheme="main" onClick={handleFinishFilesConfirm.bind(this, "add")}>
              adicionar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Fragment>
  );
});

export default StatusChange;
