import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { api, setOneOrMany, translator } from "lib";
import { AsyncSelect, CustomerLogo, SyncSelect } from "components";
import { useCacheState, useStickyState } from "hooks";
import { statuses } from "consts";
import { MdClose, MdRefresh, MdSearch } from "react-icons/md";
import { HiOutlineFilter } from "react-icons/hi";

let loadCustomersTimeout, loadTeamsTimeout, loadUsersTimeout, loadDemandsTimeout;

const Filters = ({ onQuery, onRefresh, isLoading }) => {
  const location = useLocation();
  const [query, setQuery] = useStickyState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.query"),
        defaultValue: location.state ?? {
          status: statuses.tasks.filter((o) => ["unscheduled", "pending", "started", "paused"].indexOf(o.value) !== -1),
        },
        useCached: _.isObject(location.state) === false,
        _v: 2,
      }),
      [location.pathname, location.state]
    )
  );
  const [isOpen, setIsOpen] = useCacheState(
    useMemo(
      () => ({
        key: location.pathname.concat("filters.isOpen"),
        defaultValue: false,
      }),
      []
    )
  );
  const [formData, setFormData] = useState(query);
  const [isFiltering, setIsFiltering] = useState(false);
  const backgroundColor = useColorModeValue("gray.50", "blackAlpha.300");
  const containerProps = useMemo(
    () => isOpen && { padding: { base: "10px", lg: "20px" }, backgroundColor, marginBottom: 8 },
    [isOpen, backgroundColor]
  );

  useEffect(() => {
    const response = {};
    const toObjectId = (data) => _.map(data, (o) => ["@ObjectId", o._id]);
    setOneOrMany("nid", query.nid, response, (v) => parseInt(v));
    if (query.status?.length) _.set(response, `status.$in`, _.map(query.status, "value"));
    if (query.customer?._id) _.set(response, `["customer._id"]`, query.customer._id);
    if (query.customer?.length) _.set(response, `["customer._id"].$in`, toObjectId(query.customer));
    if (query.classification?.length) _.set(response, `["customer.classification"]`, query.classification);
    if (query.team?.length) _.set(response, `["team._id"].$in`, toObjectId(query.team));
    if (query.responsible?.length) _.set(response, `["responsible._id"].$in`, toObjectId(query.responsible));
    if (query.demand?.length) _.set(response, `["demand._id"].$in`, toObjectId(query.demand));
    if (query.isDelayed?.length) _.set(response, `isDelayed`, query.isDelayed === "yes");
    setIsFiltering(Object.keys(response).length > 0);
    onQuery(response);
  }, [onQuery, query]);

  const handleSubmit = useCallback(() => {
    setQuery(formData);
  }, [setQuery, formData]);

  const handleClean = useCallback(() => {
    setQuery({});
    setFormData({});
  }, [setQuery, setFormData]);

  const handleLoadCustomers = useCallback((search, cb) => {
    clearTimeout(loadCustomersTimeout);
    loadCustomersTimeout = setTimeout(async () => {
      const params = {
        search,
        query: { status: { $ne: "inactive" } },
        sort: { tradingName: 1 },
        perPage: 20,
        isAutocomplete: true,
      };
      const response = await api.post("/customers", params);
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadTeams = useCallback((search, cb) => {
    clearTimeout(loadTeamsTimeout);
    loadTeamsTimeout = setTimeout(async () => {
      const response = await api.post("/teams", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadUsers = useCallback((search, cb) => {
    clearTimeout(loadUsersTimeout);
    loadUsersTimeout = setTimeout(async () => {
      const response = await api.post("/users", {
        search,
        query: { isActive: true },
        sort: { name: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  const handleLoadDemands = useCallback((search, cb) => {
    clearTimeout(loadDemandsTimeout);
    loadDemandsTimeout = setTimeout(async () => {
      const response = await api.post("/demands", {
        search,
        query: { isActive: true },
        sort: { title: 1 },
        perPage: 20,
        isAutocomplete: true,
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <Box {...containerProps} borderRadius="lg" transition="400ms">
      <Grid templateColumns="repeat(12,1fr)" gap={2} mb={8}>
        <GridItem as={HStack} justifyContent="space-between" colSpan={{ base: 12, lg: 12 }}>
          <HStack>
            <Button
              variant="outline"
              colorScheme={isFiltering ? "main" : "gray"}
              rightIcon={<Icon as={HiOutlineFilter} />}
              onClick={() => setIsOpen((state) => !state)}
            >
              filtros
            </Button>
            {isFiltering && (
              <Button variant="outline" rightIcon={<Icon as={MdClose} />} onClick={handleClean}>
                limpar filtros
              </Button>
            )}
          </HStack>
          <HStack>
            <Button colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
            <IconButton variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoading} onClick={onRefresh} />
          </HStack>
        </GridItem>
      </Grid>
      {isOpen && (
        <>
          <Grid templateColumns="repeat(12, 1fr)" gap={2} mb={4}>
            <GridItem colSpan={{ base: 12, lg: 1 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  NID
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.nid ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, nid: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 3 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Status
                </FormLabel>
                <SyncSelect
                  menuPortalTarget={document.body}
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.status ?? []}
                  placeholder="Selecione"
                  options={statuses.tasks}
                  onChange={(status) => setFormData((state) => ({ ...state, status }))}
                  formatOptionLabel={({ color, value }) => (
                    <HStack>
                      <Box bg={color} w="10px" h="10px" borderRadius="full" />
                      <Text>{translator(value)}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Cliente
                </FormLabel>
                <AsyncSelect
                  menuPortalTarget={document.body}
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.customer ?? []}
                  defaultOptions
                  loadOptions={handleLoadCustomers}
                  placeholder="Selecione"
                  onChange={(customer) => setFormData((state) => ({ ...state, customer }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ logoUrl, tradingName, segment }) => (
                    <HStack>
                      <CustomerLogo alt={tradingName} src={logoUrl} boxSize="35px" />
                      <Box flex="1">
                        <Text fontSize="sm" noOfLines={1}>
                          {tradingName}
                        </Text>
                        <Text fontSize="xs">{segment?.title || "-"}</Text>
                      </Box>
                    </HStack>
                  )}
                  isDisabled={_.isObject(location.state?.customer)}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Classificação
                </FormLabel>
                <Input
                  size="sm"
                  variant="filled"
                  value={formData.classification ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, classification: target.value }))}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Time
                </FormLabel>
                <AsyncSelect
                  menuPortalTarget={document.body}
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.team ?? []}
                  defaultOptions
                  loadOptions={handleLoadTeams}
                  placeholder="Selecione"
                  onChange={(team) => setFormData((state) => ({ ...state, team }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Responsáveis
                </FormLabel>
                <AsyncSelect
                  menuPortalTarget={document.body}
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.responsible ?? []}
                  defaultOptions
                  loadOptions={handleLoadUsers}
                  placeholder="Selecione"
                  onChange={(responsible) => setFormData((state) => ({ ...state, responsible }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ avatarUrl, name }) => (
                    <HStack>
                      <Avatar size="xs" name={name} src={avatarUrl} />
                      <Text>{name}</Text>
                    </HStack>
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Demandas
                </FormLabel>
                <AsyncSelect
                  menuPortalTarget={document.body}
                  size="sm"
                  variant="filled"
                  isMulti
                  value={formData.demand ?? []}
                  defaultOptions
                  loadOptions={handleLoadDemands}
                  placeholder="Selecione"
                  onChange={(demand) => setFormData((state) => ({ ...state, demand }))}
                  getOptionValue={({ _id }) => _id}
                  formatOptionLabel={({ title }) => title}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 12, lg: 4 }}>
              <FormControl>
                <FormLabel fontSize="xs" mb="5px">
                  Atrasado?
                </FormLabel>
                <Select
                  size="sm"
                  variant="filled"
                  value={formData.isDelayed ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, isDelayed: target.value }))}
                >
                  <option value="">Todos</option>
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
              </FormControl>
            </GridItem>
          </Grid>
          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="main" rightIcon={<Icon as={MdSearch} />} isLoading={isLoading} onClick={handleSubmit}>
              aplicar
            </Button>
          </HStack>
        </>
      )}
    </Box>
  );
};

export default Filters;
