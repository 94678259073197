import _ from "lodash";

const groups = [
  {
    key: "tasks",
    label: "Tarefas",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "cancel", label: "Cancelar" },
      { key: "delete", label: "Excluir" },
      { key: "updateDueDate", label: "Alterar data de vencimento" },
      { key: "reset", label: "Resetar" },
    ],
  },
  {
    key: "customers",
    label: "Clientes",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },

      {
        key: "preferences",
        label: "Preferências",
        items: [{ key: "read", label: "Visualizar" }],
      },
      {
        key: "files",
        label: "Arquivos",
        items: [
          { key: "read", label: "Visualizar" },
          { key: "create", label: "Incluir" },
          { key: "update", label: "Alterar" },
          { key: "delete", label: "Excluir" },
        ],
      },
      {
        key: "tasks",
        label: "Tarefas",
        items: [{ key: "read", label: "Visualizar" }],
      },
      {
        key: "notes",
        label: "Notas do cliente",
        items: [
          { key: "read", label: "Visualizar" },
          { key: "create", label: "Incluir" },
          { key: "update", label: "Alterar" },
          { key: "delete", label: "Excluir" },
        ],
      },
      {
        key: "meetingsNotes",
        label: "Notas de reunião",
        items: [{ key: "read", label: "Visualizar" }],
      },
      {
        key: "meetingsTechnicalNotes",
        label: "Notas técnicas",
        items: [{ key: "read", label: "Visualizar" }],
      },
      {
        key: "consultations",
        label: "Consultorias",
        items: [{ key: "read", label: "Visualizar" }],
      },
    ],
  },
  {
    key: "suppliers",
    label: "Fornecedores",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  {
    key: "customer-indicators",
    label: "Indicadores dos clientes",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  {
    key: "demands",
    label: "Demandas",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  {
    key: "indicator-types",
    label: "Tipos de indicadores",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  {
    key: "meetings",
    label: "Reuniões",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "cancel", label: "Cancelar" },
      { key: "delete", label: "Excluir" },
      { key: "updateStartAndEndDate", label: "Alterar Data/Horário" },
      { key: "reset", label: "Resetar" },
    ],
  },
  {
    key: "meeting-rooms",
    label: "Salas de reunião",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  {
    key: "meeting-types",
    label: "Tipos de reunião",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  {
    key: "bills:payable",
    label: "Pagamentos",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  {
    key: "bills:receivable",
    label: "Recebimentos",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  {
    key: "solicitations",
    label: "Solicitações",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  { key: "consultations", label: "Consultorias", items: [{ key: "read", label: "Visualizar" }] },
  {
    key: "teams",
    label: "Times",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  {
    key: "users",
    label: "Usuários",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  {
    key: "user-groups",
    label: "Grupos de usuários",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  {
    key: "segments",
    label: "Segmentos",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  {
    key: "appointments",
    label: "Compromissos",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  {
    key: "companies",
    label: "Empresas",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  {
    key: "cost-centers",
    label: "Centros de custos",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  {
    key: "accounts",
    label: "Contas",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  {
    key: "dashboards",
    label: "Gestão à Vista",
    items: [
      { key: "activities", label: "Atividades" },
      { key: "scheduling", label: "Agendamento" },
      { key: "records", label: "Cadastros" },
    ],
  },
  {
    key: "bank-accounts",
    label: "Contas bancárias",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "create", label: "Incluir" },
      { key: "update", label: "Alterar" },
      { key: "delete", label: "Excluir" },
    ],
  },
  {
    key: "crons",
    label: "Crons",
    items: [
      { key: "read", label: "Visualizar" },
      { key: "execute", label: "Executar" },
    ],
  },
];

const recursive = (parentKeys, item) => {
  if (item.items) {
    const mapped = item.items.map((o) => {
      o.values = recursive([...parentKeys, o.key], o);
      return o.values;
    });
    return _.flattenDeep(mapped);
  }
  return [parentKeys.join(":")];
};

for (const item of groups) item.values = recursive([item.key], item);

export default _.sortBy(groups, [(o) => o.label]);
