import React, { useCallback, Fragment, useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { InputChips } from "components";
import { messages } from "consts";
import { yup } from "lib";
import { MdAdd } from "react-icons/md";

export const CustomerParticipants = ({ value, onChange, labelProps }) => {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const { onOpen, onClose, isOpen } = useDisclosure();

  useEffect(() => {
    setFormData({});
    setFormErrors({});
  }, [isOpen]);

  const handleSubmit = useCallback(async () => {
    try {
      const testUniqueEmail = async function (email) {
        try {
          if (value.map((o) => o.email).includes(email)) throw new Error("O e-mail informado já foi adiciona à listagem.");
          return true;
        } catch (error) {
          return this.createError({ message: error.message });
        }
      };
      const shape = {
        name: yup.string().required(messages.error.required),
        // email: yup.string().required(messages.error.required).test("unique-email", null, testUniqueEmail),
      };
      const schema = yup.object().shape(shape);
      await schema.validate(formData, { abortEarly: false });
      const tmp = [...(value || [])];
      tmp.push(formData);
      onChange(tmp);
      onClose();
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [value, formData]);

  return (
    <Fragment>
      <HStack mb="10px">
        <Text fontSize="sm" fontWeight="500" {...labelProps}>
          Participantes do cliente
        </Text>
        <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="right" closeOnBlur={false}>
          <PopoverTrigger>
            <IconButton size="xs" colorScheme="main" icon={<Icon as={MdAdd} />} />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Adicionar participante</PopoverHeader>
            <PopoverBody>
              <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                <GridItem colSpan={12}>
                  <FormControl isRequired={true} isInvalid={formErrors.name}>
                    <FormLabel fontSize="sm">Nome</FormLabel>
                    <Input
                      value={formData.name ?? ""}
                      onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))}
                    />
                    <FormErrorMessage>{formErrors.name}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={12}>
                  <FormControl isRequired={true} isInvalid={formErrors.email}>
                    <FormLabel fontSize="sm">E-mail</FormLabel>
                    <Input
                      value={formData.email ?? ""}
                      onChange={({ target }) => setFormData((state) => ({ ...state, email: target.value }))}
                    />
                    <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                  </FormControl>
                </GridItem>
              </Grid>
            </PopoverBody>
            <PopoverFooter as={HStack} justifyContent="flex-end">
              <Button size="sm" variant="outline" onClick={onClose}>
                cancelar
              </Button>
              <Button size="sm" colorScheme="main" onClick={handleSubmit}>
                adicionar
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      </HStack>
      <InputChips value={value} onChange={(participants) => onChange(participants)} isInputVisible={false} />
    </Fragment>
  );
};
