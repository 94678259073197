import React, { useMemo, useRef, useState, useCallback } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  SlideFade,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { api } from "lib";
import {
  Breadcrumb,
  CheckboxBody,
  CheckboxHeader,
  CheckboxProvider,
  ExportCsv,
  Paginator,
  PermissionedContainer,
  Portal,
  TableDrawer,
  TableEmpty,
} from "components";
import { useApiGet, useClipboard, useCustomToast, useDocumentTitle, useTable } from "hooks";
import { TbCalendarDue, TbFileExport } from "react-icons/tb";
import { MdChevronLeft, MdMoreHoriz } from "react-icons/md";
import { FiClipboard, FiEdit, FiTrash } from "react-icons/fi";
import { Content, ContentBody, ContentHeader } from "pages/Private/Container";
import defaultColumns from "./defaultColumns";
import Filters from "./filters";
import { useCorrelationKeys } from "../useCorrelationKeys";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";

export const BillsList = () => {
  const { documentTitle } = useCorrelationKeys();
  const { type } = useParams();
  useDocumentTitle(documentTitle);
  const navigate = useNavigate();
  const location = useLocation();
  const [sort, setSort] = useState({ createdAt: 1 });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState();
  const [data, isLoadingData, refreshData, errorData, fetchAllPaginatedData] = useApiGet(
    useMemo(
      () => ({
        path: `/bills/${type}`,
        params: { query, sort, page, perPage, search },
        options: { isEnabled: _.isObject(query) },
      }),
      [type, query, sort, page, perPage, search]
    )
  );
  const { columns, cells, updateTableColumns } = useTable(
    useMemo(() => ({ id: location.pathname, defaultColumns, _v: 5 }), [location.pathname])
  );
  const checks = useMemo(() => _.map(data?.data, "_id"), [data?.data]);
  const [checkeds, setCheckeds] = useState([]);
  const [isLoadingDuplicateData, setIsLoadingDuplicateData] = useState(false);
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const { isOpen: isOpenDuplicateDialog, onOpen: onOpenDuplicateDialog, onClose: onCloseDuplicateDialog } = useDisclosure();
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const { isOpen: isOpenExportData, onOpen: onOpenExportData, onClose: onCloseExportData } = useDisclosure();
  const toast = useCustomToast();
  const tableDrawerRef = useRef();
  const copyToClipboard = useClipboard();

  const handleDuplicateData = useCallback(async () => {
    try {
      setIsLoadingDuplicateData(true);
      await api.put(`/bills/${type}/duplicate`, checkeds);
      setCheckeds([]);
      refreshData();
    } catch (error) {
      if (error.isHandled) return;
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDuplicateData(false);
      onCloseDuplicateDialog();
    }
  }, [type, checkeds, onCloseDeleteDialog, toast, refreshData]);

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete(`/bills/${type}`, { data: checkeds });
      setCheckeds([]);
      refreshData();
    } catch (error) {
      if (error.isHandled) return;
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
      onCloseDeleteDialog();
    }
  }, [type, checkeds, onCloseDeleteDialog, toast, refreshData]);

  const handleTableDrawerChange = useCallback(
    ({ sort, perPage, columns }) => {
      setSort(sort);
      setPerPage(perPage);
      updateTableColumns(columns);
    },
    [updateTableColumns]
  );

  const handleFetchDataToExport = useCallback(async () => {
    const data = await fetchAllPaginatedData();
    return _.flatMap(data, (bill) => _.map(bill.allocation, (allocation) => ({ ...bill, allocation })));
  }, [fetchAllPaginatedData]);

  return (
    <>
      <Content>
        <ContentHeader>
          <HStack justify="space-between">
            <HStack>
              {location.state && (
                <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
                  voltar
                </Button>
              )}
              <Breadcrumb items={[{ label: "cadastros" }, { to: `/bills/${type}`, label: documentTitle.toLowerCase() }]} />
            </HStack>
            <SlideFade in={checkeds.length === 0} hidden={checkeds.length} offsetY="-20px">
              <HStack>
                <Button as={RouterLink} to="new" size="sm" colorScheme="main">
                  incluir cadastro
                </Button>
                <Box>
                  <Menu>
                    <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />}>
                      mais ações
                    </MenuButton>
                    <Portal>
                      <MenuList fontSize="sm">
                        <MenuItem onClick={onOpenExportData}>
                          <HStack>
                            <Icon as={TbFileExport} />
                            <Text>exportar {documentTitle.toLowerCase()}</Text>
                          </HStack>
                        </MenuItem>
                      </MenuList>
                    </Portal>
                  </Menu>
                </Box>
              </HStack>
            </SlideFade>
          </HStack>
          <Heading my="15px" size="md">
            {documentTitle}
          </Heading>
          <Filters
            onSearch={setSearch}
            onQuery={setQuery}
            onRefresh={refreshData}
            onPage={setPage}
            isLoading={isLoadingData}
            onTableDrawer={() => tableDrawerRef.current.open()}
          />
        </ContentHeader>

        <ContentBody>
          <CheckboxProvider checks={checks} checkeds={checkeds} onChange={setCheckeds}>
            <Table size="sm" whiteSpace="nowrap">
              <Thead>
                <Tr>
                  <Th>
                    <CheckboxHeader />
                  </Th>
                  {cells.map(({ accessor, title }) => (
                    <Th key={accessor}>{title}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {_.map(data?.data, (item) => (
                  <Tr key={item._id} _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.900" } }}>
                    <Td>
                      <HStack>
                        <CheckboxBody value={item._id} />
                        <Box>
                          <Menu placement="right-start">
                            <MenuButton as={IconButton} size="xs" colorScheme="main" icon={<Icon as={MdMoreHoriz} />} />
                            <MenuList>
                              <MenuGroup title={item.name} pb="5px">
                                <MenuItem icon={<Icon as={FiClipboard} />} onClick={() => copyToClipboard(item._id)}>
                                  copiar código
                                </MenuItem>
                                <MenuItem
                                  icon={<Icon as={HiOutlineDocumentDuplicate} />}
                                  onClick={() => {
                                    setCheckeds([item._id]);
                                    onOpenDuplicateDialog();
                                  }}
                                >
                                  duplicar
                                </MenuItem>
                                <MenuItem icon={<Icon as={FiEdit} />} as={RouterLink} to={`edit/${item._id}`}>
                                  editar
                                </MenuItem>
                                <PermissionedContainer required={`bills:${type}:delete`}>
                                  <MenuDivider />
                                  <MenuItem
                                    icon={<Icon as={FiTrash} />}
                                    onClick={() => {
                                      setCheckeds([item._id]);
                                      onOpenDeleteDialog();
                                    }}
                                  >
                                    excluir
                                  </MenuItem>
                                </PermissionedContainer>
                              </MenuGroup>
                            </MenuList>
                          </Menu>
                        </Box>
                        {item.isDelayed && (
                          <Tooltip label="Atrasado">
                            <Center bg="red.500" w="25px" h="25px" borderRadius="full">
                              <Icon as={TbCalendarDue} color="white" />
                            </Center>
                          </Tooltip>
                        )}
                      </HStack>
                    </Td>
                    {cells.map(({ accessor, formatter, render }) => (
                      <Td key={accessor}>{formatter?.(item) ?? render?.(item)}</Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {isLoadingData && (
              <Center p="30px">
                <Spinner />
              </Center>
            )}
            <TableEmpty isLoading={isLoadingData} size={_.size(data?.data)} />
          </CheckboxProvider>
        </ContentBody>
      </Content>

      <Divider />

      <SlideFade in={checkeds.length} hidden={checkeds.length === 0} offsetY="20px">
        <HStack p="20px">
          <Text flex="1">{checkeds.length} selecionados</Text>
          <Button size="sm" variant="outline" leftIcon={<Icon as={HiOutlineDocumentDuplicate} />} onClick={onOpenDuplicateDialog}>
            duplicar cadastros
          </Button>
          <Button size="sm" variant="outline" leftIcon={<Icon as={FiTrash} />} onClick={onOpenDeleteDialog}>
            excluir cadastros
          </Button>
        </HStack>
      </SlideFade>

      <SlideFade in={checkeds.length === 0} hidden={checkeds.length} offsetY="20px">
        <Box p="20px">
          <Paginator loading={isLoadingData} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
        </Box>
      </SlideFade>

      <TableDrawer
        ref={tableDrawerRef}
        defaultColumns={defaultColumns}
        columns={columns}
        sort={sort}
        perPage={perPage}
        onChange={handleTableDrawerChange}
      />

      <ExportCsv
        filename="solicitacoes"
        onFetchData={handleFetchDataToExport}
        columns={columns}
        isOpen={isOpenExportData}
        onClose={onCloseExportData}
      />

      <AlertDialog isOpen={isOpenDeleteDialog} onClose={onCloseDeleteDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir os registros selecionados?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={onCloseDeleteDialog}>Cancelar</Button>
            <Button colorScheme="red" onClick={handleDeleteData} isLoading={isLoadingDeleteData}>
              Excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog isOpen={isOpenDuplicateDialog} onClose={onCloseDuplicateDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente duplicar os registros selecionados?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={onCloseDuplicateDialog}>Cancelar</Button>
            <Button colorScheme="blue" onClick={handleDuplicateData} isLoading={isLoadingDuplicateData}>
              Duplicar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
