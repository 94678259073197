import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import * as yup from "yup";
import { api } from "lib";
import { messages } from "consts";
import { useCustomToast, useDocumentTitle } from "hooks";
import { DocumentHistory, PermissionedContainer } from "components";
import AppointmentsListContext from "./context";
import { MdHistory } from "react-icons/md";
import ReactInputMask from "react-input-mask";

export const AppointmentsDetails = () => {
  const { selected, setSelected, refreshData } = useContext(AppointmentsListContext);
  const _id = useMemo(() => selected?._id, [selected]);
  useDocumentTitle(_id ? "Editar compromisso" : "Novo compromisso");
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    const formData = { ...selected };
    if (formData.startDate) formData.startDate = moment(formData.startDate).format("DD/MM/YYYY HH:mm");
    if (formData.endDate) formData.endDate = moment(formData.endDate).format("DD/MM/YYYY HH:mm");
    setFormData(formData);
  }, [selected]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        _id ? await api.patch(`/appointments/${_id}`, data) : await api.put("/appointments", data);
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        setSelected();
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, toast]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const isEndDateValid = function (endDate) {
        return this.parent.startDate <= endDate;
      };
      const schema = yup.object().shape({
        email: yup.string().required(messages.error.required),
        startDate: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
        endDate: yup
          .date()
          .typeError(messages.error.invalidDate)
          .required(messages.error.required)
          .test("is-end-date-valid", messages.error.endDateLowerThanEndDate, isEndDateValid),
      });
      const data = {
        ...formData,
        startDate: moment(formData.startDate, "DD/MM/YYYY HH:mm").toDate(),
        endDate: moment(formData.endDate, "DD/MM/YYYY HH:mm").toDate(),
      };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <Drawer isOpen={_.isObject(selected)} onClose={() => setSelected()}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader as={HStack} justifyContent="space-between">
          <Box>
            <Heading size="md">Compromisso</Heading>
            <Text fontSize="sm">{_id ? formData?.email : "Novo cadastro"}</Text>
          </Box>
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </DrawerHeader>

        <DrawerBody>
          <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
            <GridItem colSpan={12}>
              <FormControl isRequired={true} isInvalid={formErrors.email}>
                <FormLabel fontSize="sm">E-mail</FormLabel>
                <Input
                  type="email"
                  value={formData.email ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, email: target.value }))}
                />
                <FormErrorMessage>{formErrors.email}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem colSpan={12}>
              <FormControl isRequired={true} isInvalid={formErrors.startDate}>
                <FormLabel fontSize="sm">Data/Hora início</FormLabel>
                <Input
                  as={ReactInputMask}
                  mask="99/99/9999 99:99"
                  value={formData.startDate ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, startDate: target.value }))}
                />
                <FormErrorMessage>{formErrors.startDate}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem colSpan={12}>
              <FormControl isRequired={true} isInvalid={formErrors.endDate}>
                <FormLabel fontSize="sm">Data/Hora fim</FormLabel>
                <Input
                  as={ReactInputMask}
                  mask="99/99/9999 99:99"
                  value={formData.endDate ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, endDate: target.value }))}
                />
                <FormErrorMessage>{formErrors.endDate}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem colSpan={12}>
              <FormControl isRequired={true} isInvalid={formErrors.description}>
                <FormLabel fontSize="sm">Descrição</FormLabel>
                <Textarea
                  value={formData.description ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, description: target.value }))}
                  minH="200px"
                />
                <FormErrorMessage>{formErrors.description}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>
        </DrawerBody>

        <DrawerFooter as={HStack} justifyContent="flex-end">
          <PermissionedContainer required={"appointments:".concat(_id ? "update" : "create")}>
            <Button size="sm" colorScheme="main" isLoading={isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
          </PermissionedContainer>
          <Button size="sm" variant="ghost" onClick={() => setSelected()}>
            fechar
          </Button>
        </DrawerFooter>

        {_id && <DocumentHistory path={`/appointments/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />}
      </DrawerContent>
    </Drawer>
  );
};
