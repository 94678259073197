import React, { useMemo, useRef, useState, useCallback, Fragment } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  SlideFade,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import _ from "lodash";
import { api } from "lib";
import { useApiGet, useClipboard, useCustomToast, useDocumentTitle, useTable } from "hooks";
import {
  Breadcrumb,
  CheckboxBody,
  CheckboxHeader,
  CheckboxProvider,
  ExportCsv,
  Paginator,
  Portal,
  TableDrawer,
  TableEmpty,
  PermissionedContainer,
} from "components";
import { TbFileExport } from "react-icons/tb";
import { MdBlock, MdMoreHoriz, MdRefresh, MdSearch } from "react-icons/md";
import { BiCog } from "react-icons/bi";
import { FiClipboard, FiEdit, FiTrash } from "react-icons/fi";
import { Content, ContentBody, ContentHeader } from "pages/Private/Container";
import defaultColumns from "./defaultColumns";
import AppointmentsListContext from "./context";
import { AppointmentsDetails } from "./details";
import Import from "./import";

export const AppointmentsList = () => {
  useDocumentTitle("Compromissos");
  const location = useLocation();
  const [sort, setSort] = useState({ startDate: -1 });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [search, setSearch] = useState("");
  const [data, isLoadingData, refreshData, errorData, fetchAllPaginatedData] = useApiGet(
    useMemo(() => ({ path: "/appointments", params: { sort, page, perPage, search } }), [sort, page, perPage, search])
  );
  const { columns, cells, updateTableColumns } = useTable(
    useMemo(() => ({ id: location.pathname, defaultColumns, _v: 2 }), [location.pathname])
  );
  const checks = useMemo(
    () =>
      _(data?.data)
        .filter((o) => !o.meeting)
        .map("_id")
        .value(),
    [data?.data]
  );
  const [checkeds, setCheckeds] = useState([]);
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const { isOpen: isOpenExportData, onOpen: onOpenExportData, onClose: onCloseExportData } = useDisclosure();
  const [selected, setSelected] = useState();
  const toast = useCustomToast();
  const tableDrawerRef = useRef();
  const copyToClipboard = useClipboard();

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    const [{ value }] = e.target;
    setSearch(value);
    setPage(0);
  }, []);

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete("/appointments", { data: checkeds });
      setCheckeds([]);
      refreshData();
    } catch (error) {
      if (error.isHandled) return;
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
      onCloseDeleteDialog();
    }
  }, [checkeds, onCloseDeleteDialog, toast, refreshData]);

  const handleTableDrawerChange = useCallback(
    ({ sort, perPage, columns }) => {
      setSort(sort);
      setPerPage(perPage);
      updateTableColumns(columns);
    },
    [updateTableColumns]
  );

  return (
    <AppointmentsListContext.Provider value={{ selected, setSelected, refreshData }}>
      <Content>
        <ContentHeader>
          <HStack justify="space-between">
            <Breadcrumb
              items={[
                { label: "configurações" },
                { to: "/settings#records", label: "cadastros" },
                { to: "/settings/records/appointments", label: "compromissos" },
              ]}
            />
            <SlideFade in={checkeds.length === 0} hidden={checkeds.length} offsetY="-20px">
              <HStack>
                <PermissionedContainer required="appointments:create">
                  <Import />
                </PermissionedContainer>
                <PermissionedContainer required="appointments:create">
                  <Button size="sm" colorScheme="main" onClick={() => setSelected({})}>
                    incluir cadastro
                  </Button>
                </PermissionedContainer>
                <Box>
                  <Menu>
                    <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />}>
                      mais ações
                    </MenuButton>
                    <Portal>
                      <MenuList fontSize="sm">
                        <MenuItem onClick={onOpenExportData}>
                          <HStack>
                            <Icon as={TbFileExport} />
                            <Text>exportar compromissos</Text>
                          </HStack>
                        </MenuItem>
                      </MenuList>
                    </Portal>
                  </Menu>
                </Box>
              </HStack>
            </SlideFade>
          </HStack>
          <Heading my="15px" size="md">
            Compromissos
          </Heading>
          <HStack mb="30px">
            <form onSubmit={handleSearch}>
              <InputGroup width="xs">
                <Input variant="filled" placeholder="Pesquisar..." />
                <InputRightElement>
                  <Icon as={MdSearch} />
                </InputRightElement>
              </InputGroup>
            </form>
            <HStack flex="1" justify="flex-end">
              <IconButton variant="outline" icon={<Icon as={MdRefresh} />} fontSize="sm" isLoading={isLoadingData} onClick={refreshData} />
              <IconButton fontSize="sm" variant="outline" icon={<Icon as={BiCog} />} onClick={() => tableDrawerRef.current.open()} />
            </HStack>
          </HStack>
        </ContentHeader>

        <ContentBody>
          <CheckboxProvider checks={checks} checkeds={checkeds} onChange={setCheckeds}>
            <Table size="sm" whiteSpace="nowrap">
              <Thead>
                <Tr>
                  <Th>
                    <CheckboxHeader />
                  </Th>
                  {cells.map(({ accessor, title }) => (
                    <Th key={accessor}>{title}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {_.map(data?.data, (item) => (
                  <Tr key={item._id} _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.900" } }}>
                    <Td>
                      <HStack>
                        {item.meeting ? (
                          <Tooltip label="Este compromisso é derivado de uma reunião. Qualquer edição deve ser feita através da reunião de origem.">
                            <Center w="16px">
                              <Icon as={MdBlock} />
                            </Center>
                          </Tooltip>
                        ) : (
                          <CheckboxBody value={item._id} />
                        )}
                        <Box>
                          <Menu placement="right-start">
                            <MenuButton as={IconButton} size="xs" colorScheme="main" icon={<Icon as={MdMoreHoriz} />} />
                            <MenuList>
                              <MenuGroup title={item.name} pb="5px">
                                <MenuItem icon={<Icon as={FiClipboard} />} onClick={() => copyToClipboard(item._id)}>
                                  copiar código
                                </MenuItem>
                                {!item.meeting && (
                                  <Fragment>
                                    <PermissionedContainer required="appointments:update">
                                      <MenuItem icon={<Icon as={FiEdit} />} onClick={() => setSelected(item)}>
                                        editar
                                      </MenuItem>
                                    </PermissionedContainer>
                                    <PermissionedContainer required="appointments:delete">
                                      <MenuDivider />
                                      <MenuItem
                                        icon={<Icon as={FiTrash} />}
                                        onClick={() => {
                                          setCheckeds([item._id]);
                                          onOpenDeleteDialog();
                                        }}
                                      >
                                        excluir
                                      </MenuItem>
                                    </PermissionedContainer>
                                  </Fragment>
                                )}
                              </MenuGroup>
                            </MenuList>
                          </Menu>
                        </Box>
                      </HStack>
                    </Td>
                    {cells.map(({ accessor, formatter, render }) => (
                      <Td key={accessor}>{formatter?.(item) ?? render?.(item, { setSelected })}</Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {isLoadingData && (
              <Center p="30px">
                <Spinner />
              </Center>
            )}
            <TableEmpty isLoading={isLoadingData} size={_.size(data?.data)} onNew={() => setSelected({})} />
          </CheckboxProvider>
        </ContentBody>
      </Content>

      <Divider />

      <SlideFade in={checkeds.length} hidden={checkeds.length === 0} offsetY="20px">
        <HStack p="20px" justify="space-between">
          <Text>{checkeds.length} selecionados</Text>
          <Button size="sm" variant="outline" leftIcon={<Icon as={FiTrash} />} onClick={onOpenDeleteDialog}>
            excluir cadastros
          </Button>
        </HStack>
      </SlideFade>

      <SlideFade in={checkeds.length === 0} hidden={checkeds.length} offsetY="20px">
        <Box p="20px">
          <Paginator loading={isLoadingData} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
        </Box>
      </SlideFade>

      <TableDrawer
        ref={tableDrawerRef}
        defaultColumns={defaultColumns}
        columns={columns}
        sort={sort}
        perPage={perPage}
        onChange={handleTableDrawerChange}
      />

      <ExportCsv
        filename="compromissos"
        onFetchData={fetchAllPaginatedData}
        columns={columns}
        isOpen={isOpenExportData}
        onClose={onCloseExportData}
      />

      <AlertDialog isOpen={isOpenDeleteDialog} onClose={onCloseDeleteDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir os registros selecionados?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={onCloseDeleteDialog}>Cancelar</Button>
            <Button colorScheme="red" onClick={handleDeleteData} isLoading={isLoadingDeleteData}>
              Excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AppointmentsDetails />
    </AppointmentsListContext.Provider>
  );
};
