import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Icon,
  IconButton,
  Spinner,
  StackDivider,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useApiGet } from "hooks";
import { MdPrint, MdRefresh } from "react-icons/md";
import { PiNotepadBold } from "react-icons/pi";
import CustomersDetailsContext from "../context";
import { TbExternalLink } from "react-icons/tb";
import PrintDetailing from "./printDetailing";
import { SyncSelect } from "components";

export const MeetingsNotesContext = createContext();

const MeetingsNotes = () => {
  const { _id } = useParams();
  const { setCounters, setIsLoadingCounters } = useContext(CustomersDetailsContext);
  const { isOpen: isOpenPrintDetailing, onOpen: onOpenPrintDetailing, onClose: onClosePrintDetailing } = useDisclosure();
  const [query, setQuery] = useState({});
  const [_meetingsNotes, isLoadingMeetingsNotes, refreshMeetingsNotes] = useApiGet(
    useMemo(() => {
      const params = {
        query: { "customer._id": ["@ObjectId", _id], status: "finished", notes: { $exists: true, $ne: "" } },
        sort: { startDate: -1 },
        perPage: -1,
      };
      return { path: `/meetings`, params, options: { isEnabled: !!_id } };
    }, [_id])
  );
  const meetingsNotes = useMemo(() => {
    const ids = _.map(query.meetingTypes, "_id");
    if (ids.length === 0) return _meetingsNotes;
    return {
      data: _.filter(_meetingsNotes?.data, (o) => ids.indexOf(o.meetingType._id) !== -1),
      size: _meetingsNotes?.size,
    };
  }, [query, _meetingsNotes]);
  const meetingTypes = useMemo(
    () =>
      _(_meetingsNotes?.data)
        .map((o) => o.meetingType)
        .uniqBy((o) => o._id)
        .value(),
    [_meetingsNotes?.data]
  );

  useEffect(() => {
    setCounters((state) => ({ ...state, meetingsNotes: meetingsNotes?.size ?? 0 }));
  }, [setCounters, meetingsNotes?.size]);

  useEffect(() => {
    setIsLoadingCounters((state) => ({ ...state, meetingsNotes: isLoadingMeetingsNotes }));
  }, [setIsLoadingCounters, isLoadingMeetingsNotes]);

  return (
    <MeetingsNotesContext.Provider value={{ meetingsNotes }}>
      <HStack mb="20px">
        <Text flex="1" fontSize="sm" fontWeight="semibold">
          {meetingsNotes?.size ?? 0} registros adicionados
        </Text>
        <SyncSelect
          size="sm"
          value={query.meetingTypes}
          options={meetingTypes}
          placeholder="Tipos de reunião"
          onChange={(meetingTypes) => setQuery((state) => ({ ...state, meetingTypes }))}
          getOptionValue={({ _id }) => _id}
          formatOptionLabel={({ title }) => title}
          isClearable={true}
          isMulti={true}
        />
        <IconButton
          size="sm"
          variant="outline"
          icon={<Icon as={MdPrint} />}
          onClick={onOpenPrintDetailing}
          isDisabled={_.size(meetingsNotes?.data) === 0}
        />
        <IconButton
          size="sm"
          variant="outline"
          icon={<Icon as={MdRefresh} />}
          isLoading={isLoadingMeetingsNotes}
          onClick={refreshMeetingsNotes}
        />
      </HStack>

      <VStack align="stretch" divider={<StackDivider />} spacing={4}>
        {_.map(meetingsNotes?.data, (item) => (
          <Box key={item._id}>
            <HStack mb="5px" spacing={4}>
              <Box flex="1">
                <Heading size="sm">{item.finishedBy?.name ?? "-"}</Heading>
                <Text fontSize="xs">Reunião finalizada em {moment(item.finishedAt).format("DD/MM/YYYY [às] HH:mm")}</Text>
              </Box>
              <Box textAlign="right">
                <Text fontSize="xs">Tipo de reunião</Text>
                <Text fontSize="sm">{item.meetingType?.title || "-"}</Text>
              </Box>
              <Button
                as={RouterLink}
                to={`/meetings/edit/${item._id}`}
                target="_blank"
                variant="outline"
                rightIcon={<Icon as={TbExternalLink} />}
              >
                abrir reunião
              </Button>
            </HStack>
            <Text fontSize="sm">{item.notes}</Text>
          </Box>
        ))}
      </VStack>

      {isLoadingMeetingsNotes ? (
        <Center p="40px">
          <Spinner />
        </Center>
      ) : (
        _.size(meetingsNotes?.data) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={PiNotepadBold} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhuma nota de reunião adicionada
              </Text>
              <Text fontSize="sm">Este cliente ainda não possui notas de reunião adicionadas.</Text>
            </Box>
          </Center>
        )
      )}

      <PrintDetailing isOpen={isOpenPrintDetailing} onClose={onClosePrintDetailing} />
    </MeetingsNotesContext.Provider>
  );
};

export default MeetingsNotes;
