import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Container from "./Container";
import { Home } from "./Home";
import { MeetingsList, MeetingsDetails, MeetingsGrid } from "./Meetings";
import { UsersList, UsersDetails, UserPreferences } from "./Users";
import { CustomersList, CustomersDetails } from "./Customers";
import { CustomerIndicatorsList, CustomerIndicatorsDetails } from "./CustomerIndicators";
import { TasksDetails, TasksGrid, TasksList } from "./Tasks";
import { SolicitationsDetails, SolicitationsList } from "./Solicitations";
import { ConsultationsList } from "./Consultations";
import { VersionsDetails, VersionsList, VersionsView } from "./Versions";
import * as Settings from "./Settings";
import * as Dashboards from "./Dashboards";
import { SuppliersDetails, SuppliersList } from "./Supplier";
import { BillsDetails, BillsList } from "./Bills";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Container />}>
          <Route index={true} path="home" element={<Home />} />

          <Route path="meetings">
            <Route index={true} path="grid" element={<MeetingsGrid />} />
            <Route path="list" element={<MeetingsList />} />
            <Route path="new" element={<MeetingsDetails />} />
            <Route path="edit/:_id" element={<MeetingsDetails />} />
          </Route>

          <Route path="users">
            <Route index={true} element={<UsersList />} />
            <Route path="new" element={<UsersDetails />} />
            <Route path="edit/:_id" element={<UsersDetails />} />
            <Route path="preferences" element={<UserPreferences />} />
          </Route>

          <Route path="customers">
            <Route index={true} element={<CustomersList />} />
            <Route path="new" element={<CustomersDetails />} />
            <Route path="edit/:_id" element={<CustomersDetails />} />
          </Route>

          <Route path="suppliers">
            <Route index={true} element={<SuppliersList />} />
            <Route path="new" element={<SuppliersDetails />} />
            <Route path="edit/:_id" element={<SuppliersDetails />} />
          </Route>

          <Route path="customer-indicators">
            <Route index={true} element={<CustomerIndicatorsList />} />
            <Route path="new" element={<CustomerIndicatorsDetails />} />
            <Route path="edit/:_id" element={<CustomerIndicatorsDetails />} />
          </Route>

          <Route path="tasks">
            <Route index={true} path="grid" element={<TasksGrid />} />
            <Route path="list" element={<TasksList />} />
            <Route path="new" element={<TasksDetails />} />
            <Route path="edit/:_id" element={<TasksDetails />} />
          </Route>

          <Route path="solicitations">
            <Route index={true} element={<SolicitationsList />} />
            <Route path="new" element={<SolicitationsDetails />} />
            <Route path="edit/:_id" element={<SolicitationsDetails />} />
          </Route>

          <Route path="consultations">
            <Route index={true} element={<ConsultationsList />} />
          </Route>

          <Route path="bills/:type">
            <Route index={true} element={<BillsList />} />
            <Route path="new" element={<BillsDetails />} />
            <Route path="edit/:_id" element={<BillsDetails />} />
          </Route>

          <Route path="versions">
            <Route index={true} element={<VersionsList />} />
            <Route path="new" element={<VersionsDetails />} />
            <Route path="edit/:_id" element={<VersionsDetails />} />
            <Route path="view/:_id" element={<VersionsView />} />
          </Route>

          <Route path="dashboards">
            <Route index={true} element={<Dashboards.Container />} />
            <Route path="activities" element={<Dashboards.Activities />} />
            <Route path="scheduling" element={<Dashboards.Scheduling />} />
            <Route path="records" element={<Dashboards.Records />} />
          </Route>

          <Route path="settings">
            <Route index={true} element={<Settings.Container />} />

            <Route path="records">
              <Route path="teams">
                <Route index={true} element={<Settings.Records.TeamsList />} />
                <Route path="new" element={<Settings.Records.TeamsDetails />} />
                <Route path="edit/:_id" element={<Settings.Records.TeamsDetails />} />
              </Route>

              <Route path="meeting-rooms">
                <Route index={true} element={<Settings.Records.MeetingRoomsList />} />
                <Route path="new" element={<Settings.Records.MeetingRoomsDetails />} />
                <Route path="edit/:_id" element={<Settings.Records.MeetingRoomsDetails />} />
              </Route>

              <Route path="demands">
                <Route index={true} element={<Settings.Records.DemandsList />} />
                <Route path="new" element={<Settings.Records.DemandsDetails />} />
                <Route path="edit/:_id" element={<Settings.Records.DemandsDetails />} />
              </Route>

              <Route path="meeting-types">
                <Route index={true} element={<Settings.Records.MeetingTypesList />} />
                <Route path="new" element={<Settings.Records.MeetingTypesDetails />} />
                <Route path="edit/:_id" element={<Settings.Records.MeetingTypesDetails />} />
              </Route>

              <Route path="indicator-types">
                <Route index={true} element={<Settings.Records.IndicatorTypesList />} />
                <Route path="new" element={<Settings.Records.IndicatorTypesDetails />} />
                <Route path="edit/:_id" element={<Settings.Records.IndicatorTypesDetails />} />
              </Route>

              <Route path="user-groups">
                <Route index={true} element={<Settings.Records.UserGroupsList />} />
                <Route path="new" element={<Settings.Records.UserGroupsDetails />} />
                <Route path="edit/:_id" element={<Settings.Records.UserGroupsDetails />} />
              </Route>

              <Route path="segments">
                <Route index={true} element={<Settings.Records.SegmentsList />} />
                <Route path="new" element={<Settings.Records.SegmentsDetails />} />
                <Route path="edit/:_id" element={<Settings.Records.SegmentsDetails />} />
              </Route>

              <Route path="accounts">
                <Route index={true} element={<Settings.Records.AccountsList />} />
                <Route path="new" element={<Settings.Records.AccountsDetails />} />
                <Route path="edit/:_id" element={<Settings.Records.AccountsDetails />} />
              </Route>

              <Route path="appointments">
                <Route index={true} element={<Settings.Records.AppointmentsList />} />
              </Route>

              <Route path="companies">
                <Route index={true} element={<Settings.Records.CompaniesList />} />
                <Route path="new" element={<Settings.Records.CompaniesDetails />} />
                <Route path="edit/:_id" element={<Settings.Records.CompaniesDetails />} />
              </Route>

              <Route path="cost-centers">
                <Route index={true} element={<Settings.Records.CostCentersList />} />
                <Route path="new" element={<Settings.Records.CostCentersDetails />} />
                <Route path="edit/:_id" element={<Settings.Records.CostCentersDetails />} />
              </Route>

              <Route path="bank-accounts">
                <Route index={true} element={<Settings.Records.BankAccountsList />} />
                <Route path="new" element={<Settings.Records.BankAccountsDetails />} />
                <Route path="edit/:_id" element={<Settings.Records.BankAccountsDetails />} />
              </Route>

              <Route path="crons">
                <Route index={true} element={<Settings.Records.CronsList />} />
                <Route path="new" element={<Settings.Records.CronsDetails />} />
                <Route path="detailing/:topic" element={<Settings.Records.CronsDetails />} />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="*" element={<Navigate to="/home" replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
