import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { api, yup } from "lib";
import MeetingsDetailsContext from "./context";
import { useParams } from "react-router-dom";
import { MdArrowDropDown, MdClose, MdOutlineCheck, MdSearch } from "react-icons/md";
import ReactInputMask from "react-input-mask";
import { SyncSelect } from "components";
import { LuCalendarClock, LuClock } from "react-icons/lu";
import { useCustomToast } from "hooks";
import { messages } from "consts";

const Appointment = ({ item, meetingRoomsKeyValue, onClose }) => {
  const { setFormData } = useContext(MeetingsDetailsContext);
  const { isOpen, onToggle } = useDisclosure();

  const handleSelect = useCallback(
    (dateTime, meetingRoomId) => {
      setFormData((state) => {
        const tmp = {
          ...state,
          startDate: moment(dateTime).toDate(),
          endDate: moment(dateTime).add(state.predictedDurationInMinutes, "minutes").toDate(),
        };
        if (meetingRoomId) {
          tmp.mainMeetingRoom = { _id: meetingRoomId, title: meetingRoomsKeyValue[meetingRoomId].title };
          tmp.participants = state.participants.map((o) => ({ ...o, meetingRoom: tmp.mainMeetingRoom }));
        }
        return tmp;
      });
      onClose();
    },
    [item, meetingRoomsKeyValue]
  );

  return (
    <Box borderWidth="1px" borderRadius="lg">
      <HStack p="10px">
        <Icon as={LuCalendarClock} />
        <Text flex="1" fontSize="sm" fontWeight="semibold">
          {moment(item.date).format("DD/MM/YYYY")}
        </Text>
        <IconButton size="xs" icon={<Icon as={MdArrowDropDown} />} onClick={onToggle} />
      </HStack>
      {isOpen && (
        <Fragment>
          <Divider />
          <VStack alignItems="stretch" p="10px">
            {_.map(item.slots, (slot) => (
              <HStack p="10px" borderWidth="1px" borderRadius="lg" _hover={{ bg: "blackAlpha.50" }}>
                <HStack spacing={1}>
                  <Icon as={LuClock} />
                  <Text fontSize="sm">{moment(slot.time).format("HH:mm")}</Text>
                </HStack>
                {_.isArray(slot.availableRooms) ? (
                  <Box flex="1" textAlign="right">
                    {_.map(slot.availableRooms, (meetingRoomId) => (
                      <Button
                        display="inline-block"
                        mr={1}
                        size="xs"
                        colorScheme="green"
                        rightIcon={<Icon as={MdOutlineCheck} />}
                        onClick={() => handleSelect(slot.time, meetingRoomId)}
                      >
                        {meetingRoomsKeyValue[meetingRoomId].title}
                      </Button>
                    ))}
                  </Box>
                ) : (
                  <Box flex="1" textAlign="right">
                    <IconButton size="xs" colorScheme="green" icon={<Icon as={MdOutlineCheck} />} onClick={() => handleSelect(slot.time)} />
                  </Box>
                )}
              </HStack>
            ))}
          </VStack>
        </Fragment>
      )}
    </Box>
  );
};

const Vacancy = () => {
  const { _id } = useParams();
  const { formData: parentFormData, meetingRooms } = useContext(MeetingsDetailsContext);
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const meetingRoomsKeyValue = useMemo(
    () =>
      _(meetingRooms?.data)
        .map(({ _id, title }) => ({ _id, title }))
        .keyBy("_id")
        .value(),
    [meetingRooms]
  );
  const toast = useCustomToast();

  useEffect(() => {
    setFormData((state) => ({
      ...state,
      fromDate: moment().format("DD/MM/YYYY"),
      meetingRooms: [],
    }));
    setAppointments([]);
  }, [isOpen, meetingRooms?.data]);

  const handleFindVacancy = useCallback(async (data) => {
    try {
      setIsLoading(true);
      const response = await api.post(`/appointments/_/vacancy`, data);
      setAppointments(response);
    } catch (error) {
      if (error.isHandled) return;
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const shape = {
        fromDate: yup.date().typeError(messages.error.invalidDate).required(messages.error.required),
      };
      if (parentFormData.placement !== "external")
        shape.meetingRooms = yup.array().min(1, messages.error.required).required(messages.error.required);
      const data = {
        emails: [],
        meeting: _id,
        meetingRooms: _.map(formData.meetingRooms, "_id"),
        fromDate: moment(formData.fromDate, "DD/MM/YYYY").toDate(),
        slotDurationMinutes: parentFormData.predictedDurationInMinutes,
      };
      for (const item of parentFormData.participants) data.emails.push(item.user.email);
      for (const item of parentFormData.customerParticipants) data.emails.push(item.email);
      const schema = yup.object().shape(shape);
      await schema.validate(data, { abortEarly: false });
      handleFindVacancy(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [
    _id,
    formData.fromDate,
    formData.meetingRooms,
    parentFormData.placement,
    parentFormData.participants,
    parentFormData.predictedDurationInMinutes,
    handleFindVacancy,
  ]);

  return (
    <Fragment>
      <Button size="sm" variant="outline" leftIcon={<Icon as={MdSearch} />} onClick={onOpen}>
        Buscar vacância
      </Button>
      <Modal size="2xl" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack} justifyContent="space-between">
            <Box>
              <Text fontSize="md">Buscar vacância</Text>
              <Text fontSize="xs" fontWeight="normal">
                Por favor informe os parâmetros para busca de horários disponíveis.
              </Text>
            </Box>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
          </ModalHeader>
          <ModalBody>
            <HStack w="100%">
              <Box flex="1">
                <FormControl>
                  <FormLabel fontSize="xs" mb="4px">
                    Horários a partir de
                  </FormLabel>
                  <Input
                    size="sm"
                    as={ReactInputMask}
                    mask="99/99/9999"
                    value={formData.fromDate || ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, fromDate: target.value }))}
                  />
                </FormControl>
              </Box>

              {parentFormData.placement !== "external" && (
                <Box flex="1">
                  <FormControl isRequired={true} isInvalid={formErrors.meetingRooms}>
                    <FormLabel fontSize="xs" mb="4px">
                      Salas de reunião
                    </FormLabel>
                    <SyncSelect
                      size="sm"
                      isMulti
                      value={formData.meetingRooms}
                      options={meetingRooms?.data}
                      placeholder="Selecione"
                      selectedOptionStyle="check"
                      onChange={(meetingRooms) => setFormData((state) => ({ ...state, meetingRooms }))}
                      getOptionValue={({ _id }) => _id}
                      getOptionLabel={({ title }) => title}
                      isClearable={true}
                    />
                    <FormErrorMessage>{formErrors.meetingRooms}</FormErrorMessage>
                  </FormControl>
                </Box>
              )}

              <Box>
                <FormControl>
                  <FormLabel fontSize="xs" mb="4px">
                    &nbsp;
                  </FormLabel>
                  <IconButton size="sm" colorScheme="green" icon={<Icon as={MdSearch} />} onClick={handleSubmit} isLoading={isLoading} />
                </FormControl>
              </Box>
            </HStack>
          </ModalBody>
          <ModalBody>
            <VStack alignItems="stretch">
              {_.map(appointments, (item) => (
                <Appointment {...{ item, meetingRoomsKeyValue, onClose }} />
              ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default Vacancy;
