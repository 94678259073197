import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VisuallyHidden,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { messages } from "consts";
import papaparse from "papaparse";
import { MdAttachFile, MdClose, MdOutlineCloudUpload } from "react-icons/md";
import moment from "moment";
import { api } from "lib";
import { useCustomToast } from "hooks";

const parse = (file) => {
  return new Promise((resolve) => {
    papaparse.parse(file, {
      header: true,
      skipEmptyLines: true,
      delimiter: ";",
      complete: resolve,
    });
  });
};

const Import = () => {
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const [data, setData] = useState([]);
  const formRef = useRef();
  const inputRef = useRef();
  const toast = useCustomToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setData([]);
  }, [isOpen]);

  const handleFileChange = useCallback(async ({ target }) => {
    try {
      const [file] = target.files;
      const result = await parse(file);
      if (result.length > 500) throw new Error(messages.error.importListSize);
      const data = result.data.map((item) => ({
        email: item["E-MAIL"],
        startDate: moment(item["INÍCIO"], "DD/MM/YYYY HH:mm").toDate(),
        endDate: moment(item["FIM"], "DD/MM/YYYY HH:mm").toDate(),
        description: item["DESCRIÇÃO"],
      }));
      setData(data);
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      formRef.current.reset();
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoadingSaveData(true);
      await api.put("/appointments", data);
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      onClose();
    } catch (error) {
      if (error.isHandled) return;
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingSaveData(false);
    }
  }, [data, onClose]);

  return (
    <Fragment>
      <Button size="sm" variant="outline" rightIcon={<Icon as={MdAttachFile} />} onClick={onOpen}>
        importar compromissos
      </Button>
      <Modal size="6xl" isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack}>
            <Text flex="1">Importação de compromissos</Text>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={onClose} />
          </ModalHeader>
          <ModalBody>
            {data.length === 0 ? (
              <Center py="60px">
                <VStack maxW="lg">
                  <Icon as={MdAttachFile} boxSize="100px" />
                  <Heading size="lg" textAlign="center">
                    Selecione o arquivo
                  </Heading>
                  <Text textAlign="center">
                    Para iniciar a importação selecione o arquivo com os dados. É importante que o arquivo siga o exemplo abaixo.
                  </Text>
                  <Center>
                    <Image src={require("assets/imgs/appointments.png")} />
                  </Center>
                  <Box h="5px" />
                  <HStack>
                    <Button variant="outline" leftIcon={<Icon as={MdAttachFile} />} onClick={() => inputRef.current.click()}>
                      selecionar arquivo
                    </Button>
                  </HStack>
                </VStack>
              </Center>
            ) : (
              <Table size="sm" whiteSpace="nowrap">
                <Thead>
                  <Tr>
                    <Th>E-mail</Th>
                    <Th>Início</Th>
                    <Th>Fim</Th>
                    <Th>Descrição</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item.email}</Td>
                      <Td>{moment(item.startDate).format("DD/MM/YYYY HH:mm")}</Td>
                      <Td>{moment(item.endDate).format("DD/MM/YYYY HH:mm")}</Td>
                      <Td>{item.description}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </ModalBody>
          <ModalFooter>
            <HStack justifyContent="space-between">
              <Button size="sm" onClick={() => setData([])}>
                limpar dados
              </Button>
              <Button
                size="sm"
                colorScheme="green"
                leftIcon={<Icon as={MdOutlineCloudUpload} />}
                onClick={handleSubmit}
                isDisabled={data.length === 0}
                isLoading={isLoadingSaveData}
              >
                importar dados
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
        <VisuallyHidden>
          <form ref={formRef}>
            <input ref={inputRef} type="file" accept=".csv" onChange={handleFileChange} />
          </form>
        </VisuallyHidden>
      </Modal>
    </Fragment>
  );
};

export default Import;
